export const colors = {
  primary: {
    main: '#083FCF',
    m0: '#000000',
    m10: '#001354',
    m20: '#002486',
    m30: '#0036BB',
    m40: '#254FDD',
    m50: '#466AF7',
    m60: '#6C88FF',
    m70: '#93A6FF',
    m80: '#B8C4FF',
    m90: '#DDE1FF',
    m95: '#EFEFFF',
    m99: '#FEFBFF',
    m100: '#FFFFFF',
    bg: '#DDE1FF',
  },
  secondary: {
    main: '#FC7D55',
    m0: '#000000',
    m10: '#3A0A00',
    m20: '#5E1700',
    m30: '#842503',
    m40: '#A43C1A',
    m50: '#C55430',
    m60: '#E66D46',
    m70: '#FF8B67',
    m80: '#FFB59E',
    m90: '#FFDBD0',
    m95: '#FFEDE8',
    m99: '#FFFBFF',
    m100: '#FFFFFF',
    bg: '#FFDBD0',
  },
  tertiary: {
    main: '#793BC6',
    m0: '#000000',
    m10: '#2A0054',
    m20: '#450086',
    m30: '#601AAD',
    m40: '#793BC6',
    m50: '#9456E1',
    m60: '#AF72FD',
    m70: '#C496FF',
    m80: '#D9B9FF',
    m90: '#EEDCFF',
    m95: '#F8EDFF',
    m99: '#FFFBFF',
    m100: '#FFFFFF',
    bg: '#EFE2FF',
  },
  quaternary: {
    main: '#149257',
    m40: '#149257',
    bg: '#DCF2EA',
  },
  error: {
    main: '#BA1A1A',
    m0: '#000000',
    m10: '#410002',
    m20: '#690005',
    m30: '#93000A',
    m40: '#BA1A1A',
    m50: '#DE3730',
    m60: '#FF5449',
    m70: '#FF897D',
    m80: '#FFB4AB',
    m90: '#FFDAD6',
    m95: '#FFEDEA',
    m99: '#FFFBFF',
    m100: '#FFFFFF',
    bg: '#FFDBD0',
  },
  warning: {
    main: '#FFB020',
    bg: '#FFEFD2',
  },
  neutral: {
    main: '#5E5E62',
    m0: '#000000',
    m10: '#1B1B1F',
    m20: '#303034',
    m30: '#363B4E',
    m40: '#5E5E62',
    m50: '#77767A',
    m60: '#919094',
    m70: '#ACAAAF',
    m80: '#C8C6CA',
    m90: '#E4E1E6',
    m95: '#F2F0F4',
    m96: '#F5F6F9',
    m99: '#FEFBFF',
    m100: '#FFFFFF',
  },
  neutral_variant: {
    main: '#5D5E67',
    m0: '#000000',
    m10: '#1A1B23',
    m20: '#2F3038',
    m30: '#45464F',
    m40: '#5D5E67',
    m50: '#767680',
    m60: '#90909A',
    m70: '#ABAAB4',
    m80: '#C6C5D0',
    m90: '#E2E1EC',
    m95: '#F1F0FA',
    m99: '#FEFBFF',
    m100: '#FFFFFF',
  },
};

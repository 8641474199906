import {Nunito, Outfit} from 'next/font/google';

export const fontPrimary = Nunito({
  subsets: ['latin'],
  variable: '--default-font',
  weight: ['400', '500', '600', '700'],
});
export const fontSecondary = Outfit({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
});

export const customTypography = {
  fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
  display_large: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 700,
    lineHeight: '64px',
    fontSize: 57,
  },
  display_medium: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 700,
    lineHeight: '52px',
    fontSize: 45,
  },
  display_small: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 700,
    lineHeight: '44px',
    fontSize: 36,
  },
  headline_large: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '40px',
    fontSize: 32,
  },
  headline_medium: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '36px',
    fontSize: 28,
  },
  headline_small: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '32px',
    fontSize: 24,
  },
  title_large: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '28px',
    fontSize: 20,
  },
  title_medium: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 16,
  },
  title_small: {
    fontFamily: ['sans-serif', 'Arial', fontSecondary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: 14,
  },
  label_large: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 16,
  },
  label_medium: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: 14,
  },
  label_small: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 600,
    lineHeight: '16px',
    fontSize: 12,
  },
  body_normal_large: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 400,
    lineHeight: '24px',
    fontSize: 16,
  },
  body_normal_medium: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 400,
    lineHeight: '20px',
    fontSize: 14,
  },
  body_normal_small: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 400,
    lineHeight: '16px',
    fontSize: 12,
  },
  body_bold_large: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: 16,
  },
  body_bold_medium: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 700,
    lineHeight: '20px',
    fontSize: 14,
  },
  body_bold_small: {
    fontFamily: ['sans-serif', 'Arial', fontPrimary.style.fontFamily],
    fontWeight: 700,
    lineHeight: '16px',
    fontSize: 12,
  },
};

import {useEscolas} from '@/hooks/escola';
import Cookies from 'js-cookie';
import {useSession} from 'next-auth/react';
import {useRouter} from 'next/router';
import {createContext, useContext, useState} from 'react';

const EscolaContext = createContext();

export function useEscola() {
  return useContext(EscolaContext);
}

export function EscolaProvider({children}) {
  const router = useRouter();
  const {data: session} = useSession();
  const queryOptions = {
    enabled: !!session,
  };
  const {data: escolas} = useEscolas(queryOptions);

  const getEscolaCookie = () => {
    return Cookies.get('escola') || null;
  };

  const [selectedEscola, setSelectedEscola] = useState(getEscolaCookie());

  const setEscolaCookie = (escolaId) => {
    Cookies.set('escola', escolaId, {
      expires: 45,
      path: '/',
      domain: process.env.NEXT_PUBLIC_DOMAIN_URL,
      sameSite: 'none',
      secure: true,
    });
    setSelectedEscola(escolaId);
  };

  const deleteEscolaCookie = () => {
    Cookies.remove('escola', {
      path: '/',
      domain: process.env.NEXT_PUBLIC_DOMAIN_URL,
    });
  };

  const willRedirect = checkCookie(
    escolas,
    router,
    getEscolaCookie(),
    setEscolaCookie
  );

  return (
    <EscolaContext.Provider
      value={{
        selectedEscola,
        deleteEscolaCookie,
        selectEscola: setEscolaCookie,
      }}
    >
      {!willRedirect && children}
    </EscolaContext.Provider>
  );
}

const checkCookie = (escolas, router, selectedEscola, selectEscola) => {
  if (selectedEscola) return 0;

  if (!escolas) return 0;

  if (escolas.length === 1) {
    selectEscola(escolas[0].id);
    return 0;
  }

  if (router.pathname === '/escola' || router.query.callbackUrl === '/escola') {
    return 0;
  }

  if (router.pathname !== '/auth/login') {
    router.push({
      pathname: '/escola',
      query: {
        callbackUrl: router.asPath,
      },
    });
  }

  router.push('/escola');
  return 1;
};

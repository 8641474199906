import {primaryColor} from './colors';

const switchColor = primaryColor;

export const themeForm = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        display: 'flex',
        margin: '8px 0',
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginBottom: '4px',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 56,
        height: 32,
        padding: 0,
      },
      track: {
        borderRadius: 100,
      },
      switchBase: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 6,
        paddingRight: 6,
        '&.Mui-checked': {
          '& .MuiSwitch-thumb:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              switchColor.contrastText
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
          },
        },
      },
      thumb: {
        boxShadow: 'none',
        width: 24,
        height: 24,
        margin: 0,
        '&:before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="17" width="16" viewBox="0 0 17 16"><path fill="${encodeURIComponent(
            switchColor.constastText
          )}" d="M13.1673 4.2735L12.2273 3.3335L8.50065 7.06016L4.77398 3.3335L3.83398 4.2735L7.56065 8.00016L3.83398 11.7268L4.77398 12.6668L8.50065 8.94016L12.2273 12.6668L13.1673 11.7268L9.44065 8.00016L13.1673 4.2735Z"/></svg>')`,
        },
      },
    },
  },
};

import {useQueryClient} from 'react-query';
import {
  buildGetRequest,
  useGetArrayRequest,
  useGetRequest,
  usePostRequest,
} from './base_hooks';

export function useEscolas(options) {
  return useGetArrayRequest(['crm', 'escola'], null, options);
}

export function useEscolaById(escolaId, options) {
  return useGetRequest(['crm', 'escola', escolaId], null, options);
}

export function useGetEscola() {
  return useGetRequest(['crm', 'escola', 'namespace'], getEscola({}));
}

export function getEscola({req}) {
  return buildGetRequest(['crm', 'escola', 'namespace'], [], req);
}

export function useCreateEscola() {
  const queryClient = useQueryClient();
  const successCallback = () => {
    queryClient.invalidateQueries(['crm', 'escola']);
  };
  return usePostRequest(['crm', 'escola'], successCallback);
}

import {useEscolaById} from '@/hooks/escola';
import * as Sentry from '@sentry/nextjs';
import {useSession} from 'next-auth/react';
import {usePostHog} from 'posthog-js/react';
import {createContext, useContext, useEffect} from 'react';
import {useEscola} from './EscolaProvider';

const IdentificationContext = createContext();

export function useIdentification() {
  return useContext(IdentificationContext);
}

export function IdentificationProvider({children}) {
  const {selectedEscola} = useEscola();
  const {data: session} = useSession();
  const queryEnabled = {enabled: !!selectedEscola};
  const {data: escola} = useEscolaById(selectedEscola, queryEnabled);
  const posthog = usePostHog();

  useEffect(() => {
    if (!session?.user || !escola) {
      return;
    }

    const user = {
      id: session.user.id,
      email: session.user.email,
      nome: session.user.name,
      cargo: session.user.role,
      escola: escola.nome || '-',
    };

    posthog.identify(user.id, user);
    Sentry.setUser(user);
  }, [session, posthog, escola]);

  return (
    <IdentificationContext.Provider>{children}</IdentificationContext.Provider>
  );
}

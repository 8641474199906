import {ThemeProvider} from '@mui/material/styles';
import {SessionProvider} from 'next-auth/react';
import React, {useState} from 'react';

import {
  Hydrate,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

import {AuthPageGate} from '@/config/auth';
import getTheme from '@/config/theme';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import {SnackbarProvider, closeSnackbar} from 'notistack';

import {EscolaProvider} from '@/components/contexts/EscolaProvider';
import {SidebarProvider} from '@/components/contexts/SidebarProvider';
import {buildBatchedGetRequest} from '@/hooks/base_hooks';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import Head from 'next/head';

import {AlertProvider} from '@/components/contexts/AlertProvider';
import {IdentificationProvider} from '@/components/contexts/IdentificationProvider';
import LinearProgress from '@mui/material/LinearProgress';
import {useRouter} from 'next/router';
import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';
import {useEffect} from 'react';
import '../styles/variables.css';
if (typeof window !== 'undefined') {
  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
  const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;

  posthog.init(posthogKey, {
    api_host: posthogHost || 'https://app.posthog.com',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') {
        posthog.debug();
      }
    },
    capture_pageview: false,
    autocapture: false,
  });
}

export default function App({Component, pageProps: {session, ...pageProps}}) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({}),
        defaultOptions: {
          queries: {
            queryFn: ({queryKey}) => {
              return buildBatchedGetRequest(queryKey, {})();
            },
            staleTime: 1000 * 60 * 3, // 30 seconds
          },
        },
      })
  );
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => posthog.capture('$pageview');

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeStart', () => {
      setIsLoading(true);
    });

    router.events.on('routeChangeComplete', () => {
      setIsLoading(false);
    });

    router.events.on('routeChangeError', () => {
      setIsLoading(false);
    });

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const {theme} = getTheme();

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <PostHogProvider client={posthog}>
      <SessionProvider session={session}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ReactQueryDevtools />
            <EscolaProvider>
              <IdentificationProvider />
              <SidebarProvider>
                <AlertProvider>
                  <ThemeProvider theme={theme}>
                    <div id="theme-root">
                      <CssBaseline />
                      <SnackbarProvider
                        autoHideDuration={3000}
                        preventDuplicate={true}
                        action={(snackbarId) => (
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => closeSnackbar(snackbarId)}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                      />
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="pt-br"
                      >
                        <AuthPageGate
                          isPublic={Component?.auth?.isPublic}
                          permissions={Component?.auth?.permissions}
                        >
                          <Head>
                            <title>Crescer | Lumni Tech</title>
                          </Head>
                          {isLoading && (
                            <LinearProgress
                              sx={{
                                position: 'fixed',
                                left: 0,
                                top: 0,
                                width: '100vw',
                                zIndex: '1300',
                              }}
                            />
                          )}
                          {getLayout(<Component {...pageProps} />)}
                        </AuthPageGate>
                      </LocalizationProvider>
                    </div>
                  </ThemeProvider>
                </AlertProvider>
              </SidebarProvider>
            </EscolaProvider>
          </Hydrate>
        </QueryClientProvider>
      </SessionProvider>
      {/* <ZendeskSnippet /> */}
    </PostHogProvider>
  );
}

const _ZendeskSnippet = () => {
  return (
    <>
      {/* <!-- Start of lumnieducaohelp Zendesk Widget script --> */}
      {/* eslint-disable-next-line */}
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=49d93287-a123-4528-a1c0-99a25027085c"
      >
        {' '}
      </script>
      {/* <!-- End of lumnieducaohelp Zendesk Widget script --> */}
    </>
  );
};

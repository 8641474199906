import {useEffect, useState} from 'react';

function useLocalStorage(key) {
  const isClient = typeof window !== 'undefined';
  const [value, setValue] = useState(() => {
    if (isClient) {
      const storedValue = localStorage.getItem(key);
      const hasStoredValue = storedValue && storedValue !== 'undefined';
      return hasStoredValue ? JSON.parse(storedValue) : undefined;
    }
    return undefined;
  });

  useEffect(() => {
    if (isClient && value !== undefined) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value, isClient]);

  const remove = () => {
    if (isClient) {
      localStorage.removeItem(key);
      setValue(undefined);
    }
  };
  return [value, setValue, remove];
}

export {useLocalStorage};

import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import {ptBR as corePtBR} from '@mui/material/locale';
import {createTheme} from '@mui/material/styles';
import {ptBR} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {Nunito} from 'next/font/google';
import {colors} from './customColors';
import {customTypography} from './customTypography';
import {themeForm} from './theme/theme-form';

const font = Nunito({subsets: ['latin'], variable: '--default-font'});
const primaryColor = {
  main: colors.primary.main,
  dark: colors.primary.main,
  darker: colors.primary.main,
};

// const secondaryColor = {
//   main: colors.secondary.main,
//   dark: colors.secondary.main,
//   darker: colors.secondary.main,
// };
const neutralColor = '#D8DAE5';

export default function getTheme() {
  dayjs.locale('pt-br');
  return {
    theme: createTheme(
      {
        palette: {
          primary: colors.primary,
          secondary: colors.secondary,
          tertiary: colors.tertiary,
          neutral: colors.neutral,
          white: {
            main: '#ffffff',
          },
          background: {
            default: colors.neutral.m96,
          },
        },
        typography: {
          ...customTypography,
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                fontWeight: 600,
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: '12px',
                boxShadow: 'none',
                backgroundColor: 'white',
                border: '1px solid #D8DAE5',
              },
            },
          },
          MuiCardHeader: {
            styleOverrides: {
              root: {},
              title: {
                fontSize: '20px',
                fontWeight: 700,
              },
            },
          },
          ...themeForm,
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: primaryColor.dark,
                color: 'white',
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              ...customTypography,
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                underline: 'none',
                textDecoration: 'none',
                ...customTypography.body_normal_medium,
                fontWeight: 600,
              },
            },
          },
          MuiDialogTitle: {
            defaultProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#fff',
                },
              },
            },
            styleOverrides: {
              root: {
                fontWeight: 700,
              },
            },
          },
          MuiDataGrid: {
            styleOverrides: {
              root: {
                borderRadius: 0,
                border: 0,
              },
              columnHeaders: {
                backgroundColor: neutralColor,
                borderRadius: 0,
              },
              footerContainer: {
                border: 0,
              },
            },
          },
        },
      },
      ptBR,
      corePtBR
    ),
    fontClass: font,
  };
}

export function DateTimeInputTheme() {
  return {
    theme: createTheme({
      typography: {
        ...customTypography,
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              height: 334,
            },
          },
        },
        MuiPickersLayout: {
          styleOverrides: {
            root: {
              height: 380,
            },
            contentWrapper: {
              gridRow: 1,
            },
          },
        },
        MuiMultiSectionDigitalClock: {
          styleOverrides: {
            root: {
              height: 300,
              maxHeight: '84%',
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: 0,
              position: 'absolute',
              bottom: 5,
              right: 0,
            },
          },
        },
        MuiYearCalendar: {
          styleOverrides: {
            root: {
              height: 250,
            },
          },
        },
      },
    }),
  };
}

export const CustomSelectTheme = styled((props) => <Select {...props} />)(
  ({height, width, backgroundColor, typography}) => ({
    height: height || 'auto',
    width: width || 'auto',
    '& .MuiInputBase-input': {
      '&:focus': {
        backgroundColor: 'inherit !important',
      },
    },
    '& .MuiFormControl-root': {
      backgroundColor: backgroundColor || colors.primary.m100,
    },
    '& .MuiAutocomplete-input': {
      padding: '0 !important',
    },
    '& .MuiFormLabel-root': {
      ...customTypography.title_small,
      color: colors.primary.m0,
      marginTop: -12,
      '&[data-shrink="true"]': {
        display: 'none',
      },
    },
    '& .MuiSelect-select': {
      ...customTypography[typography || 'title_large'],
      padding: 0,
    },
  })
);

export const CustomIconButton = styled(IconButton)(() => ({padding: 0}));

export const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({customStyles}) => ({
  width: customStyles.width,
  height: customStyles.height,
  padding: 0,
  display: 'flex',
  '&:active': {},
  '& .MuiSwitch-switchBase': {
    padding: customStyles.width / customStyles.height === 1.75 ? 1.75 : 2.5,
    '&.Mui-checked': {
      transform: `translateX(${customStyles.width * 0.425}px)`,
      color: colors.primary.m100,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.primary.m40,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: customStyles.height * 0.75,
    height: customStyles.height * 0.75,
    borderRadius: 13,
  },
  '& .MuiSwitch-track': {
    borderRadius: 13,
    backgroundColor: colors.neutral_variant.m90,
    border: `1.5px solid ${colors.neutral_variant.m60}`,
    boxSizing: 'border-box',
  },
}));
export function CustomLinearProgressTheme(progressEmptyColor, progressBgColor) {
  return createTheme({
    components: {
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            backgroundColor: progressEmptyColor,
          },
          bar: {
            backgroundColor: progressBgColor,
          },
        },
      },
    },
  });
}
